import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"

function PageNotFound() {
    return (
        <Layout>
            <Page pageTitle="Page not found" pageClass="page-error">
                <p>The page you're trying to reach cannot be found on this server.</p>
            </Page>
        </Layout>
    );
}

export default PageNotFound
